<template>
  <div class="content">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">Recipe Collections <span class="tag is-large is-primary">Total: {{ recipeCollections.length }}</span></h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button outlined type="is-primary" class="right" id="add-button" icon-left="plus" @click="createNewRecipeCollection">Add</b-button>
        </div>
      </div>
    </div>
    <b-table
      :data="recipeCollections"
      :hoverable="true"
      :mobile-cards="false"

      @click="openRecipeCollection"
    >
      <b-table-column width=48 label="Image" sortable v-slot="props">
        <figure class="m-0 p-0 image is-48x48">
          <img v-if="rowImageSrc(props.row) == null" class="thumbnail"  src="@/assets/listing-placeholder.png">
          <img v-else class="thumbnail" :src="rowImageSrc(props.row)">
        </figure>
      </b-table-column>
      <b-table-column field="title" label="Title" sortable searchable v-slot="props">
        <p class="has-text-weight-bold">
          {{ props.row.title }}
        </p>
      </b-table-column>
      <b-table-column field="recipes" label="Recipes" centered v-slot="props">
        <p class="has-text-weight-bold  has-text-centered">
          {{ rowNumberRecipes(props.row) }}
        </p>
      </b-table-column>
      <b-table-column field="position" label="Position" sortable centered v-slot="props">
        <p class="has-text-weight-bold has-text-centered">
          {{ props.row.position }}
        </p>
      </b-table-column>
      <b-table-column field="status" width=100 label="Status" sortable searchable centered v-slot="props">
          <span
            :class="[
                    'tag',
                    {'is-danger is-light': props.row.status == 'draft'},
                    {'is-warning': props.row.status == 'test'},
                    {'is-info': props.row.status == 'fix'},
                    {'is-success': props.row.status == 'live'}
                  ]">
              <b>{{ props.row.status }}</b>
          </span>
      </b-table-column>
      <b-table-column v-if="isDesktop()" field="created_at" width=60 label="Created" sortable centered v-slot="props">
          <span class="tag is-light">
              {{ new Date(props.row.created_at).toLocaleDateString() }}
          </span>
      </b-table-column>
      <b-table-column field="id" width=60 label="ID" searchable centered v-slot="props">
          <p class="is-size-7">{{ props.row.id }}</p>
      </b-table-column>
      <b-table-column field="options" width=20  v-slot="props">
        <b-dropdown aria-role="list" position="is-bottom-left" @click.native.stop>
            <template #trigger>
              <b-button icon-right="dots-vertical" />
            </template>
            <b-dropdown-item aria-role="listitem" @click="duplicateRecipeCollection(props.row)">
              <b-icon size="is-small" icon="content-copy"></b-icon>
              Duplicate
            </b-dropdown-item>
            <b-dropdown-item class="has-text-danger" aria-role="listitem" @click="deleteRecipeCollection(props.row)">
              <b-icon  size="is-small" icon="delete"></b-icon>
              Delete
            </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import db from "@/firebase/db"
import DateFormatter from "@/formatters/DateFormatter"

export default {
  name: 'RecipeCollectionListView',
  data() {
    return {
      recipeCollections: []
    }
  },
  firestore: {
    recipeCollections: db.collection('recipe_collections').orderBy("created_at", "desc")
  },
  methods: {
    isDesktop() {
      if (screen.width <= 760) {
        return false;
      } else {
        return true;
      }
    },
    createNewRecipeCollection() {
      db.collection("recipe_collections").add({
          created_at: Date.now(),
          title: "New recipe collection",
          description: "A new collection of recipes",
          recipes: {},
          images: [],
          status: "draft",
          position: 1,
          analytics_identifier: ""
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.$router.push({ name: 'RecipeCollectionView', params: { id: docRef.id } })
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    },
    openRecipeCollection(recipeCollection) {
      this.$router.push({ name: 'RecipeCollectionView', params: { id: recipeCollection.id } })
    },
    deleteRecipeCollection(recipeCollection) {
        console.log(recipeCollection)
        this.$buefy.dialog.confirm({
            title: 'Deleting recipeCollection',
            message: `Are you sure you want to delete Recipe Collection: <b><i>"${recipeCollection.title}"</i></b>? <br /><br />This action cannot be undone.`,
            confirmText: 'Delete Recipe Collection',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              db.collection("recipe_collections").doc(recipeCollection.id)
              .delete()
              .then(() => {
                // TODO: delete it's images from storage
                this.$buefy.toast.open({type: 'is-success', message: `Recipe collection successfully deleted!`})
              }).catch((error) => {
                this.$buefy.toast.open({type: 'is-danger', message: `Error deleting recipe collection: ${error}`})
              });
            }
        })
    },
    duplicateRecipeCollection(recipeCollection) {
      db.collection("recipe_collections").doc(recipeCollection.id)
        .get()
        .then((doc) => {
          if (doc.exists && doc.data()) {
            let newData = doc.data();
            newData['created_at'] = Date.now();
            newData['status'] = "draft";
            return db.collection("recipe_collections").add(newData)
              .then((docRef) => {
                this.$buefy.toast.open({type: 'is-success', message: `Recipe Collection successfully duplicated! ${docRef.id}`})
              });
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error duplicating recipe collection ${recipeCollection.id}: ${error}`})
        });
    },
    // rowStyle(recipeCollection) {
    //   if (mealplan.backgroundColour == null || mealplan.highlightColour == null) { return null; }

    //   return {
    //     backgroundColor: mealplan.backgroundColour.colour,
    //     borderRight: `30px solid ${mealplan.highlightColour.colour}`,
    //     color: (mealplan.backgroundColour.invertTheme == "dark") ? Colours.black.colour : Colours.white.colour
    //   }
    // },
    rowNumberRecipes(recipeCollection){
      return Object.keys(recipeCollection.recipes).length
    },
    rowImageSrc(recipeCollection) {
      if (recipeCollection.images == null){
        return null;
      }
      return (recipeCollection.images.length != 0) ? recipeCollection.images[0].src : null;
    },
    formatDate(dateTime) {
      return DateFormatter.displayDate(dateTime);
    }
  }
}

</script>
<style>
.thumbnail{
  height: 100% !important;
  object-fit: cover;
}
</style>
